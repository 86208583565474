.carousel-container {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.carousel-wrapper {
    display: flex;
    position: relative;
    /* padding-left: 25%;
    padding-right: 25%; */
}

.carousel-content-wrapper { 
    overflow: hidden;
    width: 100%;
    height: 100%;

}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-content.show-2 > * {
    width: 50%;
    flex-shrink: 0;
    flex-grow: 1;
    transform:translateX(50%);
    /* display: flex;
    justify-content: center;
    align-content: center; */
}

.imageContainer {

    background-color: black;
    height: 100;
    border-radius: .5rem;
    outline: 1px solid white;
    outline-offset: -.25em;
    box-sizing: border-box;
    padding: .25rem;
    display: flex;
    justify-content: center;
    align-content: center;
}

.aboutContainer {

    height: 100;
    border-radius: .5rem;
    outline: 1px solid white;
    outline-offset: -.25em;
    box-sizing: border-box;
    padding: .25rem;
    display: flex;
    justify-content: center;
    align-content: center;
    }

.tokenImg {
    border-radius: .5rem;
    max-height: 35vh;
    filter:brightness(50%);
    object-fit: contain;
    justify-content: center;
    align-content: center;
    overflow: hidden;

}

.logoImg {
    border-radius: .5rem;
    opacity: .4;
    max-height: 25vh;
    max-width: 100%;
    object-fit: contain;
    align-self: center;
}

.tokenData {
    position:absolute;
    left: 1rem;
    bottom: 1rem;
}

.tokenData > * {
    margin: 0;
    left: 1rem;
    bottom: 1rem;
}



.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0%;
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}


