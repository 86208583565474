.App {
  color: #CCCCCC;
  height: 100vh;
  background-color: black;
}

@media (min-height: 700px) {
  .App {
    color: #CCCCCC;
    min-height: 100%;
    background-color: black;
  }
}




#WEB3_CONNECT_MODAL_ID.web3modal-modal-card { z-index: 10 }