.imageContainer {

    background-color: black;
    border-radius: .5rem;
    outline: 1px solid white;
    outline-offset: -.25em;
    box-sizing: border-box;
    padding: .25rem;
    display: flex;
    justify-content: center;
    align-content: center;
}

.aboutContainer {

    border-radius: .5rem;
    outline: 1px solid white;
    outline-offset: -.25em;
    box-sizing: border-box;
    padding: .25rem;
    display: flex;
    justify-content: center;
    align-content: center;
    }

.tokenImg {
    border-radius: .5rem;
    max-height: 35vh;
    filter:brightness(50%);
    object-fit: contain;
    justify-content: center;
    align-content: center;
    overflow: hidden;

}

.logoImg {
    border-radius: .5rem;
    opacity: .4;
    max-height: 25vh;
    max-width: 100%;
    object-fit: contain;
    align-self: center;
}