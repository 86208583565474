.tokenData {
    position:absolute;
    left: 1rem;
    bottom: 1rem;
}

.tokenData > * {
    margin: 0;
    left: 1rem;
    bottom: 1rem;
}

.opensea {
    position:absolute;
    right: 1rem;
    bottom: 1rem;
}

.opensea > * {
    margin: 0;
    right: 1rem;
    bottom: 1rem;
}

a {
    color: #CCCCCC;
    text-decoration: none;
}